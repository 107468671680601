import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { Jobs } from "../../Interface/Jobs/jobs";
import { Constant } from "../../Shared/Models/constant";

@Injectable({
    providedIn: "root",
})
export class JobsService {
    Jobs: Observable<Jobs[]>;
    private url: string;
    Constants = new Constant();

    private Addnewjobs = this.Constants.localhost_url + "api/jobms";
    private Deletejobs = this.Constants.localhost_url + "api/job/";
    private NotYetAssigned = this.Constants.localhost_url + "api/jobms";
    private Assigned = this.Constants.localhost_url + "api/jobms";
    private Tobeapproved = this.Constants.localhost_url + "api/jobms";
    private Rejected = this.Constants.localhost_url + "api/jobms";
    private Approved = this.Constants.localhost_url + "api/jobms";
    private Completed = this.Constants.localhost_url + "api/jobms";
    private Detail = this.Constants.localhost_url + "api/jobms/";
    private bankDetails = this.Constants.localhost_url + "api/post-bank-details";
    private deleteBankDetails = this.Constants.localhost_url + "api/delete-bank/";
    private getLabourRemark = this.Constants.localhost_url + "api/get-labour-remarks/";
    private deleteLabourRemark = this.Constants.localhost_url +"api/delete-labour-remarks/";

    private VehicleDetail =
        this.Constants.localhost_url + "api/job/get-vehicle-detail";
    private VehicleVariant =
        this.Constants.localhost_url + "api/job/vehicle-variants/";

    private approvedstatusedit =
        this.Constants.localhost_url + "api/job/update-job/";
    private updateJob = this.Constants.localhost_url + "api/job/update-job/";
    private assignedemployee =
        this.Constants.localhost_url + "api/job-assign-ms";
    private jobeditdetail = this.Constants.localhost_url + "api/jobms/";
    private inspectionDetail =
        this.Constants.localhost_url + "api/update-inspection";
    private approvedinpectionsave =
        this.Constants.localhost_url + "api/ms/post/tab-data";
    private approvedinspectionget =
        this.Constants.localhost_url + "api/ms/get/tab-data";
    private rejectJob = this.Constants.localhost_url + "api/job/";
    private clientbranch = this.Constants.localhost_url + "api/clientbranch";
    private updateRemark =
        this.Constants.localhost_url + "api/job/update-remark/";
    private sentReportMail =
        this.Constants.localhost_url + "api/job/send-mail-report";
    private updateJobImageUrl =
        this.Constants.localhost_url + "api/job/update-job-image/";
    private contactPerson = this.Constants.localhost_url + "api/contactperson";
    private inspectionDetailbyid =
        this.Constants.localhost_url + "api/get-job-by-id/";
    private fileUpload = this.Constants.localhost_url + "api/file_upload";
    private manualUpload = this.Constants.localhost_url + "api/manual-upload";
    private surveyLink = this.Constants.localhost_url + "api/job-link-create/";
    private assessmentLink = this.Constants.localhost_url + "api/assessment/";
    private bodyAssessmentLink = this.Constants.localhost_url + "api/save-update-cabin-body-assessment/";
    private bodyCabinTax = this.Constants.localhost_url+"api/save-update-cabin-body-tax/";
    private getBodyAssessmentLink = this.Constants.localhost_url + "api/get-cabin-body-assessment-list/"; 
    private getBodyTaxLink = this.Constants.localhost_url +"api/get-cabin-body-tax-setting/";
    private getBodyAssessmentSummary = this.Constants.localhost_url + "api/get-cabin-body-assessment-summary/";
    private taxDepSetting = this.Constants.localhost_url + "api/taxdepsetting/";
    private isZeroDepSetting = this.Constants.localhost_url + "api/gettaxdep/";
    private inspectionjobstatus =
        this.Constants.localhost_url + "api/inspection-jobstatus/";
    private summaryLink =
        this.Constants.localhost_url + "api/getassessmentsummary/";
    private summaryDataSave =
        this.Constants.localhost_url + "api/assesment-inputupdate";
    private storeAttachmentMaster =
        this.Constants.localhost_url + "api/storeattachmentmaster";
    private deleteMasterAttachment =
        this.Constants.localhost_url + "api/deletemasterattachment?";
    private saveDynamicSectionMaster =
        this.Constants.localhost_url + "api/savedynamicsectionmaster";
    private deleteDynamicSectionMaster =
        this.Constants.localhost_url + "api/deletedynamicsectionmaster?";
    private getDynamicSectionMasterData =
        this.Constants.localhost_url + "api/getdynamicsectionmasterdata";
    private vehicleApiData =
        this.Constants.localhost_url + "api/getvehicledata";
    private getJobDetailsList =
        this.Constants.localhost_url + "api/get_job_details_list";
    private getStructureList =
        this.Constants.localhost_url + "api/ms/get-estimate-structure";
    private storeStructureData =
        this.Constants.localhost_url + "api/ms/import-estimate-structure";
    private base64URL = this.Constants.localhost_url + "api/ms/get/base-encode";
    private getStructureInfo =
        this.Constants.localhost_url + "api/ms/estimate-structure-details/";
    private feeBillRefreshURL =
        this.Constants.localhost_url + "api/fee-bill-calculation/";
   // private uploadPdfURL = this.Constants.localhost_url.replace('backend/','') +"api_new/extract_tables";
    private uploadPdfURL = "http://34.72.219.87:8300/extract_tables";
    private uploadExcellURL = "http://34.72.219.87:8300/extract_tables_excel";
   // private uploadExcellURL = this.Constants.localhost_url.replace('backend/','') +"api_new/extract_tables_excel";

   // private getUpdateForData = this.Constants.localhost_url.replace('backend/','') +"api_new/tasks/";
   private getUpdateForData = "http://34.72.219.87:8300/tasks/";
    private AddLocalWorkshop = this.Constants.localhost_url+"api/add-local-workshop";

    private EditLocalWorkshop = this.Constants.localhost_url+"api/edit-local-workshop/";

    private AddPrivateTemplate = this.Constants.localhost_url+"api/ms/add-pod-template";

    private replaceTagURL = this.Constants.localhost_url+"api/ms/replace-tags-data/";

    private GetPrivateTemplate = this.Constants.localhost_url+"api/ms/pod-template-list/"

    private saveUpdateLabourRemark = this.Constants.localhost_url+"api/save-update-labour-remarks"

    private saveEstimateDetails = this.Constants.localhost_url+"api/save-update-estimates-details/";

    private getEstimateDetails = this.Constants.localhost_url+"api/get-estimated-detail/";

    constructor(private httpClient: HttpClient) {}

    contactpersonlist(id: string): Observable<any> {
        
        return this.httpClient.get(
            `${this.contactPerson + "?all=&branch_id="}` + id
        );
    }

    getBase64URL(urlData): Observable<any> {
        
        let file = {
            url: urlData,
        };
        return this.httpClient.post(`${this.base64URL}`, file);
    }
    vehicleData(
        vehicle_make,
        vehicle_variant = "",
        vehicle_model = ""
    ): Observable<any> {
        
        return this.httpClient.get(
            `${this.vehicleApiData}?vehicle_make=${vehicle_make}&vehicle_variant=${vehicle_variant}&vehicle_model=${vehicle_model}`
        );
    }

    getJobDetails() {
        
        return this.httpClient.get(`${this.getJobDetailsList}`);
    }

    structureList(): Observable<any> {
        
        return this.httpClient.get(`${this.getStructureList}`);
    }
    Addjobs(Addjobsdata: Object,id=null): Observable<any> {
        if(id) return this.jobEditDetail(Addjobsdata,id);
        else return this.httpClient.post(`${this.Addnewjobs}`, Addjobsdata);
    }

    storeStructure(formValue: Object): Observable<any> {
        
        return this.httpClient.post(`${this.storeStructureData}`, formValue);
    }

    saveEstimateDetail(formValue: Object,id): Observable<any> {
        return this.httpClient.post(`${this.saveEstimateDetails}${id}`, formValue);
    }
    getEstimateDetail(id): Observable<any> {
        return this.httpClient.get(`${this.getEstimateDetails}` + id);
    }

    getStructureData(id): Observable<any> {
        
        return this.httpClient.get(`${this.getStructureInfo}` + id);
    }
    storeAttachment(Addattachment: Object): Observable<any> {
        
        return this.httpClient.post(
            `${this.storeAttachmentMaster}`,
            Addattachment
        );
    }
    deleteAttachment(id): Observable<any> {
        
        return this.httpClient.delete(
            `${this.deleteMasterAttachment}key=` + id
        );
    }

    JobsNotYetAssigned(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.NotYetAssigned +
                "?status=created&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`
        );
    }

    JobsAssigned(
        PageNo: string,
        SearchTxt: string,
        search_type:string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Assigned +
                "?status=pending&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo +
                "&search_type=" +
                search_type
            }`
        );
    }

    JobsTobeapproved(
        PageNo: string,
        SearchTxt: string,
        search_type:string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Tobeapproved +
                "?status=submitted&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo +
                "&search_type=" +
                search_type
            }`
        );
    }

    JobsRejected(
        PageNo: string,
        SearchTxt: string,
        search_type:string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Rejected +
                "?status=rejected&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo +
                "&search_type=" +
                search_type
            }`
        );
    }

    JobsApproved(
        PageNo: string,
        SearchTxt: string,
        search_type:string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Approved +
                "?status=approved&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo +
                "&search_type=" +
                search_type
            }`
        );
    }

    JobsCompleted(
        PageNo: string,
        SearchTxt: string,
        search_type:string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Completed +
                "?status=finalized&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo +
                "&search_type=" +
                search_type
            }`
        );
    }

    JobDelete(id: string): Observable<any> {
        
        return this.httpClient.delete(`${this.Deletejobs}` + id);
    }

    MasterDelete(id: string): Observable<any> {
        
        return this.httpClient.delete(
            `${this.deleteDynamicSectionMaster}id=` + id
        );
    }

    JobDetail(id: string): Observable<any> {
        
        return this.httpClient.get(`${this.Detail}` + id);
    }

    MasterDetail(): Observable<any> {
        
        return this.httpClient.get(`${this.getDynamicSectionMasterData}`);
    }

    SaveMasterDetail(formData): Observable<any> {
        
        return this.httpClient.post(
            `${this.saveDynamicSectionMaster}`,
            formData
        );
    }

    InspectionDetailbyid(id): Observable<any> {
        
        return this.httpClient.get(`${this.inspectionDetailbyid}` + id);
    }

    AssesmentInspectionDetailbyid(formName, id): Observable<any> {
        
        return this.httpClient.get(
            `${this.approvedinspectionget}?type=${formName}&inspection_id=` +
                id
        );
    }

    UpdateJobRemark(id: string, obj: object): Observable<any> {
        
        return this.httpClient.put(`${this.updateRemark}` + id, obj);
    }

    CreateSurveyLink(id: string): Observable<any> {
        
        return this.httpClient.get(`${this.surveyLink}` + id);
    }

    ApprovedStatusEdit(id: string): Observable<any> {
        
        return this.httpClient.put(
            `${this.rejectJob}` + id + "/approved",
            {}
        );

        /*
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.put(`${this.approvedstatusedit}`+id, approvededit);
    */
    }

    CompletedStatusEdit(id: string): Observable<any> {
        

        return this.httpClient.get(
            `${this.rejectJob + "finalize-report/"}` + id
        );

        /*
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.put(`${this.approvedstatusedit}`+id, approvededit);
    */
    }
    SummaryDataSave(formData): Observable<any> {
        
        return this.httpClient.post(`${this.summaryDataSave}`, formData);
    }

    SentMailReport(employeeedit: object, jobid: string): Observable<any> {   
        return this.httpClient.post(`${this.sentReportMail}`, employeeedit);
    }

    AssignedEmployeelist(
        employeeedit: object,
        jobid: string,
        AssignName
    ): Observable<any> {
        
        return this.httpClient.post(`${this.assignedemployee}`, employeeedit);
    }

    jobEditDetail(jobdata: Object, id: string): Observable<any> {
        jobdata['admin_branch_id'] = ""+jobdata['admin_branch_id'];
        return this.httpClient.put(`${this.jobeditdetail}` + id, jobdata);
    }
    InspectionDetail(jobdata: Object): Observable<any> {
        
        return this.httpClient.post(`${this.inspectionDetail}`, jobdata);
    }
    ApprovedFormSubmit(jobdata: Object): Observable<any> {
        
        return this.httpClient.post(`${this.approvedinpectionsave}`, jobdata);
    }

    jobUpdateDetail(jobdata: Object, id: string): Observable<any> { 
        return this.httpClient.put(`${this.updateJob}` + id, jobdata);
    }

    rejectJobService(id: string, remark: string): Observable<any> {
        
        return this.httpClient.put(
            `${this.rejectJob}` + id + "/rejected",
            { remark: remark }
        );
    }

    getVehicleDetails(obj: object): Observable<any> {
        
        return this.httpClient.post(`${this.VehicleDetail}`, obj);
    }

    getVehicleVariant(obj: object, id: string): Observable<any> {
        
        return this.httpClient.post(`${this.VehicleVariant}` + id, obj);
    }

    branchlist(id: string): Observable<any> {
        
        return this.httpClient.get(
            `${this.clientbranch + "?all=&client_id="}` + id
        );
    }

    updateJobImage(
        file: File,
        jobid: string,
        imageid: string,
        type: string
    ): Observable<any> {
        

        let formParams = new FormData();
        formParams.append("file", file);
        formParams.append("type", type);
        formParams.append("image_id", imageid);
        return this.httpClient.post(
            `${this.updateJobImageUrl}` + jobid,
            formParams
        );
    }

    uploadJobDoc(Base64Image): Observable<any> {
        
        return this.httpClient.post(`${this.fileUpload}`, Base64Image);
    }

    UploadJob(fileList): Observable<any> {
        
        return this.httpClient.post(`${this.manualUpload}`, fileList);
    }
    AssessmentDetail(id): Observable<any> {
        
        return this.httpClient.get(`${this.assessmentLink}` + id);
    }
    SummaryLink(id): Observable<any> {
        
        return this.httpClient.get(`${this.summaryLink}` + id);
    }
    TaxDepSetting(id): Observable<any> {
        
        return this.httpClient.get(`${this.taxDepSetting}` + id);
    }

    isZeroDepSettings(id): Observable<any> {
        
        return this.httpClient.get(
            `${this.isZeroDepSetting}` + id + "?IsZeroDep=1"
        );
    }
    SaveTaxDepSetting(FileData, id): Observable<any> {
        
        return this.httpClient.post(`${this.taxDepSetting}` + id, FileData);
    }
    InspectionApproved(id): Observable<any> {
        
        return this.httpClient.get(`${this.inspectionjobstatus}` + id);
    }

    AssessmentSubmit(FileData, id): Observable<any> {
        
        return this.httpClient.post(`${this.assessmentLink}` + id, FileData);
    }

    bodyCabinAssessmentSubmit (FileData, id):Observable<any>{
        return this.httpClient.post(`${this.bodyAssessmentLink}`+ id, FileData);
    }
    bodyCabinTaxSubmit(FileData, id):Observable<any>{
        return this.httpClient.post(`${this.bodyCabinTax}`+ id, FileData);
    }
    getBodyCabinAsseementApi(id): Observable<any> {
        
        return this.httpClient.get(`${this.getBodyAssessmentLink}` + id);
    }

    getBodyCabinAssementSummary(id): Observable<any>{

        return this.httpClient.get(`${this.getBodyAssessmentSummary}`+id);
    }

    getBodyCabinAssementTaxLink(id): Observable<any>{
        return this.httpClient.get(`${this.getBodyTaxLink}`+id);
    }
    saveBodyCabinAssementTaxLink(FileData,id): Observable<any>{
        return this.httpClient.post(`${this.bodyCabinTax}`+id,FileData);
    }


    uploadPDf(FileData,extension): Observable<any> {
        let header = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Access-Control-Allow-Origin':'*'
           
        });
        let url = extension=="pdf"?this.uploadPdfURL:this.uploadExcellURL;
        return this.httpClient.post(`${url}`, FileData);
    }

    getUpdateUsingId(taskId):Observable<any>{
        return this.httpClient.get(`${this.getUpdateForData}${taskId}`);
    }

    feeBillRefresh(id): Observable<any> {
        
        return this.httpClient.get(`${this.feeBillRefreshURL}${id}`);
    }

    getLocalWorkshop(id): Observable<any> {
        
        return this.httpClient.get(`${this.EditLocalWorkshop}${id}`);
    }

    getTemplateDetails(id): Observable<any> {
        
        return this.httpClient.get(`${this.GetPrivateTemplate}${id}`);
    }

    storeLocalWorkshop(FileData): Observable<any>{
        
        return this.httpClient.post(`${this.AddLocalWorkshop}`, FileData);
    }

    storeTemplate(FileData): Observable<any>{
        
        return this.httpClient.post(`${this.AddPrivateTemplate}`, FileData);
    }
    replaceTagData(id):Observable<any>{
        
        return this.httpClient.get(`${this.replaceTagURL}${id}`);
    }
    saveBankDetails(FileData):Observable<any>{
        return this.httpClient.post(`${this.bankDetails}`, FileData);
    }
    removeBankDetails(index):Observable<any>{
        return this.httpClient.delete(`${this.deleteBankDetails}${index}`);
    }
    getLabourRemarkData(admin_id,type):Observable<any>{
        return this.httpClient.get(`${this.getLabourRemark}${admin_id}/${type}`);
    }
    saveUpdateLabourRemarkData(FileData,id){
        let url = id?this.saveUpdateLabourRemark+'/'+id:this.saveUpdateLabourRemark;

        return this.httpClient.post(`${url}`, FileData);
    }
    deleteLabourRemarkApi(admin_id,id){
        return this.httpClient.delete(`${this.deleteLabourRemark}${admin_id}/${id}`);

    }
}
