import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  key = localStorage.getItem('token');
  platformCheck(RequestedPlatform){
   return localStorage.getItem('platform')==RequestedPlatform;
  }
  isAllowedAccess(role){
    if(localStorage.getItem('role')){
      console.log(this.decrypt(localStorage.getItem('AuthInfo')),localStorage.getItem('role'));
    return role== localStorage.getItem('role');
    }
    return false;
  }
  isAllowedAccessList(roles:String[]){
    if(localStorage.getItem('AuthInfo')){
      return roles.includes(this.decrypt(localStorage.getItem('AuthInfo')));
    }
    return false;
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }
  
}
